import * as React from "react";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import background from "../../images/services/intervention-24-24-7-7.jpg";
import "../../scss/style.scss";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServicesListBasic from "../../components/services/ServicesListBasic";
import ContactUs from "../../components/buttons/ContactUs";

const TrajetPrive = () => {
  return (
    <main className="page">
      <title>
        Société de sécurité spécialisée dans votre protection - Services -
        Intervention 24/24 - 7/7
      </title>
      <div id="wrapper" className="wrapper">
        <Header />
        <Banner
          category="Services"
          title="Intervention 24/24 - 7/7"
          content=""
          background={background}
          position="left"
        />
        <div className="container pt-4 pt-md-10 pb-4 pb-md-10">
          <div className="row justify-content-start">
            <div className="col-md-8">
              <div className="service service-single">
                <div className="content">
                  <h1>
                    Agents de sécurité disponibles 24h/24 et 7/7 pour protéger
                    votre entreprise et vos évènements
                  </h1>
                  <p>
                    Nous proposons des services de sécurité complets pour
                    protéger votre entreprise contre les risques de vol,
                    d'intrusion, de vandalisme et d'autres menaces. Notre équipe
                    d'agents de sécurité qualifiés est disponible 24/24 et 7/7
                    pour répondre à vos besoins de sécurité.
                  </p>
                  <h2>Agents de sécurité hautement qualifiés</h2>
                  <p>
                    Nous avons une équipe d'agents de sécurité hautement
                    qualifiés qui sont formés pour faire face à toutes les
                    situations de sécurité. Tous nos agents de sécurité sont
                    agréés et formés pour répondre à vos besoins de sécurité.
                    Nous sommes fiers de notre équipe et nous sommes convaincus
                    que nos agents de sécurité sont les meilleurs du secteur.
                  </p>
                  <h2>Surveillance en temps réel</h2>
                  <p>
                    Nous proposons une surveillance en temps réel de votre
                    entreprise pour garantir la sécurité de vos locaux et de vos
                    employés. Notre centre de surveillance est opérationnel
                    24/24 et 7/7, et nous sommes en mesure de répondre
                    rapidement à toute alerte de sécurité. Nous avons des
                    caméras de surveillance de haute qualité qui nous permettent
                    de surveiller votre entreprise à distance en temps réel.
                  </p>
                  <h2>Patrouilles de sécurité</h2>
                  <p>
                    Nous proposons des patrouilles de sécurité pour garantir la
                    sécurité de votre entreprise. Nos agents de sécurité
                    effectuent des patrouilles régulières pour détecter les
                    intrusions, les actes de vandalisme et autres menaces
                    potentielles. Nos agents de sécurité sont disponibles 24/24
                    et 7/7 pour effectuer des patrouilles de sécurité à tout
                    moment.
                  </p>
                  <h2>Interventions d'urgence</h2>
                  <p>
                    Nous proposons des interventions d'urgence pour répondre
                    rapidement à toute situation de sécurité. Notre équipe
                    d'agents de sécurité est formée pour gérer toutes les
                    situations d'urgence, y compris les cambriolages, les
                    incendies, les inondations et autres. Nous sommes
                    disponibles 24/24 et 7/7 pour intervenir rapidement en cas
                    d'urgence.
                  </p>
                  <p>
                    Nous sommes fiers de dire que nous offrons des services de
                    sécurité de qualité pour protéger votre entreprise contre
                    les risques de vol, d'intrusion, de vandalisme et d'autres
                    menaces. Notre équipe d'agents de sécurité qualifiés est
                    disponible 24/24 et 7/7 pour répondre à vos besoins de
                    sécurité. Contactez-nous dès aujourd'hui pour discuter de
                    vos besoins en matière de sécurité.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-2">
                <ContactUs theme="black" />
              </div>
            </div>
            <div className="col-md-3 offset-md-1 pt-4">
              <h3>Nos prestations :</h3>
              <ServicesListBasic />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};
export default TrajetPrive;
